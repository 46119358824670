<template>
  <div
    id="product-tile-box"
    class="product relative w-100 mb10"
    :data-expend-block="isExpendBlock"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true
    }"
    @mouseenter="hoverPath = true"
    @mouseleave="closeVideo"
  >
    <div class="shadow-overlay" />
    <div class="block no-underline product-link relative">
      <router-link
        ref="productLink"
        :to="productLink"
        data-testid="productLink"
        class="flex column product__link bg-cl-white"
        :event="linkActive ? 'click' : 'none'"
        @click.native="productClick"
        @mouseout="closeVideo"
      >
        <button v-show="product.vimeo_id && !isVimeoOpen" class="icon-video" @mouseenter="openVideo">
          <i class="path4" />
        </button>
        <div class="product-image-box relative">
          <ProductTileLabel :product="product" :min-price="minPrice" :is-product-sold="isProductSold" :info-label="getLabelFor('info_label', product.info_label)" />
          <product-image
            class="product-cover__thumb"
            :class="{'video-open': isVimeoOpen, 'product-sold-image': isProductSold}"
            :image="product.image"
            :alt="product.name | htmlDecode"
            :is-show-hover-image="hoverPath"
            :hover-image="secondThumbnailObj"
            :calc-ratio="false"
            data-testid="productImage"
          />
          <div v-if="isPlayerLoading" class="vimeo-loader">
            <spinner />
          </div>
          <vue-vimeo-player class="vimeo-video" ref="player" v-if="isVimeoOpen" :options="options" :video-id="product.vimeo_id" @ready="onReady" @play="stopLoading" @error="stopLoading" />
        </div>
        <div class="relative align-left">
          <template v-if="product.type_id === 'configurable' || product.type_id === 'sirent'">
            <p
              class="mb0 mt15 mx10 cl-light-gray uppercase align-left product-name small-space"
              v-if="!onlyImage"
            >
              {{ product.name | htmlDecode }}
            </p>
            <p v-if="isSubscriptionAvaliable" class="mb0 mt10 mx10 fs13 cl-light-gray product-price">
              <span>{{ subscription.itemsToRent }} {{ $t('items from') }}</span><b> {{ subscription.price | price(storeView) }}{{ $t('/month') }}</b>
            </p>
            <p v-else-if="minPrice" class="mb0 mt10 mx10 fs13 cl-light-gray product-price">
              <span>{{ $t('Rent') }} {{ $t('fromV2') }}</span>
              <b>
                <span :class="{'line-through': isSpecialOfferPrice}">{{ minPrice.price | price(storeView) }}</span>
                <span v-if="isSpecialOfferPrice" class="cl-special-offer">{{ minPrice.final_price | price(storeView) }}</span>
              </b>
            </p>
            <p class="mb0 mt5 mx10 fs13 cl-light-gray product-name-action">
              <template v-if="isSubscriptionAvaliable">
                {{ $t('Rent in subscription') }}
              </template>
              <template v-else>
                {{ $t('Order a try-on') }}
              </template>
            </p>
          </template>
          <template v-if="product.type_id === 'simple'">
            <p
              class="mb0 mt15 mx10 cl-light-gray uppercase align-left product-name small-space"
              v-if="!onlyImage"
            >
              {{ getLabelFor('marketplace_designer', product.marketplace_designer) }}
            </p>
            <p
              class="fs-small cl-light-gray small-space align-left marketplace-designer"
              v-if="!onlyImage"
            >
              {{ product.name | htmlDecode }}
            </p>
            <p class="mb0 mt10 mx10 fs-small cl-light-gray align-left product-price">
              {{ `${$t('Price')}: ` }} {{ product.price | price(storeView) }}
            </p>
            <button-outline v-if="!isProductSold" class="buy-button">
              {{ $t('Add to bag') }}
            </button-outline>
          </template>
          <AddToWishlist class="wishlist-btn hidden-xs" :product="product">
            <div :title="isOnWishlist ? $t('Remove') : $t('Add to favorite')">
              <i class="wishlist-icon" :class="isOnWishlist ? 'icon-Heart-full cl-light-gray' : 'icon-heart_contour cl-light-gray'" />
            </div>
          </AddToWishlist>
        </div>
      </router-link>
      <div v-if="product.type_id === 'configurable' || product.type_id === 'sirent'" class="expand__block">
        <p class="mb0 mt5 mx10 fs-small cl-light-gray align-left">
          {{ $t('Retail price') }} {{ product.retail_price | price(storeView) }}
        </p>
        <div v-if="optionsValue" class="available-option mx10 mt5 mb5 flex">
          <span v-for="option in optionsValue" :key="option + product.name" class="brdr-1 mr5 h5 cl-light-gray uppercase pointer">
            {{ option }}
          </span>
        </div>
      </div>
      <div v-else-if="product.type_id === 'simple'" class="expand__block">
        <p v-if="product.retail_price" class="mb0 mt5 mx10 fs-small cl-light-gray align-left">
          {{ `${$t('Shop price')}:` }} {{ product.retail_price | price(storeView) }}
        </p>
        <div v-if="product.size && getLabelFor('size', product.size)" class="available-option mx10 mt5 mb5 flex">
          <span class="brdr-1 mr5 h5 cl-light-gray uppercase pointer">
            {{ getLabelFor('size', product.size) }}
          </span>
        </div>
        <div v-else-if="product.shoe_size && getLabelFor('size', product.shoe_size)" class="available-option mx10 mt5 mb5 flex">
          <span class="brdr-1 mr5 h5 cl-light-gray uppercase pointer">
            {{ getLabelFor('size', product.shoe_size) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import rootStore from '@vue-storefront/core/store'
import { ProductTile } from '@vue-storefront/core/modules/catalog/components/ProductTile.ts'
import config from 'config'
import ProductImage from './ProductImage'
import { mapState } from 'vuex'
import Vue from 'vue'
import checkView from 'vue-check-view'
import CurrentPage from 'theme/mixins/currentPage'
import AddToWishlist from 'theme/components/core/blocks/Wishlist/AddToWishlist'
import { IsOnWishlist } from 'src/modules/vsf-magento-wishlist/components/IsOnWishlist'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { categoryResolver } from 'theme/helpers/categoryResolverFunction'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import { isMarketplaceProductSold } from 'theme/helpers/selling-products'
import { getNextProduct } from 'src/modules/google-tag-manager/hooks/afterRegistration'
import Spinner from 'theme/components/core/Spinner'
import ProductTileLabel from 'theme/components/core/blocks/Product-v2/components/ProductTileLabel.vue'

const vueVimeoPlayer = () => import(/* webpackChunkName: "vue-vimeo-player" */ 'vue-vimeo-player').then(player => player.vueVimeoPlayer)

Vue.use(checkView)

export default {
  mixins: [ProductTile, CurrentPage, IsOnWishlist],
  components: {
    ProductImage,
    AddToWishlist,
    vueVimeoPlayer,
    ButtonOutline,
    Spinner,
    ProductTileLabel
  },
  data () {
    return {
      isVimeoOpen: false,
      seen: false,
      hoverPath: false,
      loadingThumbnailObj: {
        src: '/assets/placeholder.svg',
        loading: '/assets/placeholder.svg',
        error: '/assets/placeholder.svg'
      },
      dragStartX: 0,
      dragStartY: 0,
      isTouch: typeof window !== 'undefined' && 'ontouchstart' in window,
      playerReady: true,
      isPlayerLoading: false,
      options: {
        muted: true,
        autoplay: true,
        loop: true,
        controls: false
      }
    }
  },
  props: {
    isExpendBlock: {
      type: Boolean,
      default: true
    },
    linkActive: {
      type: Boolean,
      default: true
    },
    onlyImage: {
      type: Boolean,
      default: false
    },
    position: {
      type: Number,
      default: 1
    },
    columns: {
      type: [Number, String],
      default: ''
    },
    reviews: {
      type: Array,
      default: () => []
    },
    list: {
      type: String,
      default: 'catalog'
    },
    subscription: {
      type: Object,
      default: () => ({
        price: '',
        itemsToRent: ''
      })
    }
  },
  methods: {
    onReady () {
      this.playerReady = true
    },
    openVideo () {
      if (!this.$device.isMobile) {
        this.isVimeoOpen = true
        this.isPlayerLoading = true
      }
    },
    stopLoading () {
      this.isPlayerLoading = false
    },
    closeVideo () {
      this.hoverPath = false
      if (!this.$device.isMobile) this.isVimeoOpen = false
    },
    onTouchEnd (e) {
      const eventPosX =
        this.isTouch && e.changedTouches && e.changedTouches.length > 0
          ? e.changedTouches[0].clientX
          : e.clientX;
      const eventPosY =
        this.isTouch && e.changedTouches && e.changedTouches.length > 0
          ? e.changedTouches[0].clientY
          : e.clientY;
      const deltaX = this.dragStartX - eventPosX;
      const deltaY = this.dragStartY - eventPosY;

      if (Math.abs(deltaX) < 8 && Math.abs(deltaY) < 8) {
        this.$emit('product-slide-click')
      }
    },
    onTouchStart (e) {
      if (e.button === 2) {
        return;
      }
      this.dragStartX = this.isTouch ? e.touches[0].clientX : e.clientX;
      this.dragStartY = this.isTouch ? e.touches[0].clientY : e.clientY;
    },
    onProductPriceUpdate (product) {
      if (product.sku === this.product.sku) {
        Object.assign(this.product, product)
      }
    },
    visibilityChanged (isVisible, entry) {
      if (
        isVisible &&
        config.products.configurableChildrenStockPrefetchDynamic &&
        config.products.filterUnavailableVariants &&
        this.product.type_id === 'configurable' &&
        this.product.configurable_children &&
        this.product.configurable_children.length > 0
      ) {
        const skus = [this.product.sku]
        for (const confChild of this.product.configurable_children) {
          const cachedItem = rootStore.state.stock.cache[confChild.id]
          if (typeof cachedItem === 'undefined' || cachedItem === null) {
            skus.push(confChild.sku)
          }
        }
        if (skus.length > 0) {
          rootStore.dispatch('stock/list', { skus: skus }) // store it in the cache
        }
      }
      if (isVisible && !this.seen) {
        if (window.dataLayer) {
          let price = this.minPrice ? this.minPrice.price : ''
          price = Math.abs(parseFloat(price)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(',', '.')
          let { deepestCategory, categoriesIdsFromResolver } = categoryResolver(this.product.category, this.$store, config.server.categoryIdsMap.mainCategoryId)
          categoriesIdsFromResolver = categoriesIdsFromResolver.length ? categoriesIdsFromResolver[0] : ''
          deepestCategory = deepestCategory.length ? deepestCategory[0] : ''
          Vue.gtm.trackEvent({
            event: 'uaevent',
            eventCategory: 'Ecommerce',
            eventAction: 'Product Impressions',
            ecommerce: {
              currencyCode: 'PLN', // TODO: get currency from state
              impressions: [{
                name: this.product.name,
                id: this.product.parentSku,
                price: price,
                category: deepestCategory,
                product_category_ids: categoriesIdsFromResolver,
                list: this.list,
                position: this.position + 1
              }]
            }
          })
          Vue.prototype.$googleTagManager.impressions({
            impressions: [
              getNextProduct({
                item: this.product,
                list: this.list,
                list_id: this.product.parentSku,
                position: this.position
              }, this.$store)
            ]
          })
        }
        this.seen = true
      }
    },
    productClick () {
      if (this.isWishlistActive) {
        this.$store.dispatch('ui/toggleWishlist')
      }
      if (window.dataLayer) {
        let price = this.minPrice ? this.minPrice.price : ''
        price = Math.abs(parseFloat(price)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        let { deepestCategory, categoriesIdsFromResolver } = categoryResolver(this.product.category, this.$store, config.server.categoryIdsMap.mainCategoryId)
        categoriesIdsFromResolver = categoriesIdsFromResolver.length ? categoriesIdsFromResolver[0] : ''
        deepestCategory = deepestCategory.length ? deepestCategory[0] : ''
        window.dataLayer.push({
          event: 'uaevent',
          eventCategory: 'Ecommerce',
          eventAction: 'Product Click',
          ecommerce: {
            click: {
              actionField: {
                list: this.list
              },
              products: [
                {
                  id: this.product.parentSku,
                  name: this.product.name,
                  price: price,
                  category: deepestCategory,
                  product_category_ids: categoriesIdsFromResolver,
                  position: this.position + 1
                }]
            }
          }
        })
        Vue.prototype.$googleTagManager.click({
          item: getNextProduct({
            item: this.product,
            list: this.list,
            list_id: this.product.parentSku,
            position: this.position
          }, this.$store)
        })
      }
    },
    getLabelFor (attr, code) {
      if (!this.listByCode[attr] || !this.listByCode[attr].options) return ''
      const option = this.listByCode[attr].options.find(option => Number(option.value) === code)
      if (!option || !option.label) return ''
      return option.label
    }
  },
  computed: {
    isSubscriptionAvaliable () {
      return this.product.subscription_points
    },
    storeView () {
      return currentStoreView()
    },
    optionsValue () {
      let values = this.product.configurable_options && this.product.configurable_options[0].values && this.product.configurable_options[0].values.map(value => value.label)
      return values
    },
    isSpecialOfferPrice () {
      return this.product.info_label && Number(this.minPrice.final_price)
    },
    minPrice () {
      if (!this.product.sirental_price) return
      let sirental_price = JSON.parse(JSON.stringify(this.product.sirental_price))
      if (sirental_price && sirental_price.length) {
        sirental_price = sirental_price
          .filter(price => price.price)
          .reduce((prev, curr) => {
            if (curr.period) {
              curr.period = Number(curr.period.replace('d', ''))
              if (curr.period !== 1) curr.period = curr.period + 1
              // workaround for new 1 day rent
            }
            if (curr.period === 3) return prev
            return (Number(prev.price) < Number(curr.price))
              ? prev
              : curr
          }, 0)
      } else {
        return null
      }
      return sirental_price
    },
    ...mapState({
      isWishlistActive: state => state.ui.wishlist
    }),
    listByCode () {
      return this.$store.state.attribute.list_by_code
    },
    isProductSold () {
      return isMarketplaceProductSold(this.product.marketplace_sold_at, this.product.type_id)
    },
    productAttribute () {
      if (this.product.news) return { class: 'new', text: this.$t('New in') }
      if (this.product.new_with_tag) return { class: 'new-with-tag', text: this.$t('Brand new') }
      if (this.product.bestseller) return { class: 'bestseller', text: this.$t('Bestseller') }
      return false
    }
  },
  mounted () {
    if (!this.linkActive) {
      this.$el.addEventListener(
        this.isTouch ? 'touchend' : 'mouseup',
        this.onTouchEnd
      )
      this.$el.addEventListener(
        this.isTouch ? 'touchstart' : 'mousedown',
        this.onTouchStart
      )
    }
  },
  destroy () {
    this.$el.removeEventListener(
      this.isTouch ? 'touchend' : 'mouseup',
      this.onTouchEnd,
      true
    )
    this.$el.removeEventListener(
      this.isTouch ? 'touchstart' : 'mousedown',
      this.onTouchStart,
      true
    )
  },
  beforeMount () {
    this.$bus.$on('product-after-priceupdate', this.onProductPriceUpdate)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-priceupdate', this.onProductPriceUpdate)
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/animations/transitions';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$gray: color(gray);
$matterhorn: color(matterhorn);

.available-option {
  span {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.delete-from-whishlist {
  transition: .2s ease-out;
  position: absolute;
  opacity: 1;
  z-index: 10;
  top: 18px;
  right: 10px;
  .wishlist-icon {
    font-size: 40px;
    @media (max-width: 767px) {
      font-size: 40px;
    }
  }
  @media (max-width: 767px) {
    right: 10px;
  }
}
.wishlist-btn {
  transition: .2s ease-out;
  position: absolute;
  opacity: 0;
  z-index: 10;
  top: 7px;
  right: 10px;
  .wishlist-icon {
    font-size: 40px;
    @media (max-width: 767px) {
      font-size: 40px;
    }
  }
  @media (max-width: 767px) {
    right: 10px;
  }
}
.product {
  @media (max-width: 767px) {
    max-width: 180px;
    margin-bottom: 0;
    height: auto;
    max-width: initital;
  }
  @media (max-width: 320px) {
    max-width: 145px;
  }
  &__link {
    @media (max-width: 767px) {
      padding-bottom: 10px;
    }
  }
  .close-icon {
    padding: 10px;
    font-size:25px;
  }
  .expand__block {
    backface-visibility: hidden;
    opacity: 0;
    // height: 70px;
    overflow: hidden;
    z-index: 2;
    @media (max-width: 767px) {
      height: 0;
    }
  }
  .shadow-overlay {
    position: absolute;
  }
  &:hover {
    transition: .2s ease-out;
    z-index: 1;
    @media (min-width: 768px) {
      .wishlist-btn {
        opacity: 1;
      }
      .expand__block {
        transition: .2s ease-out;
        opacity: 1;
      }
      .shadow-overlay {
        transition: .2s ease-out;
        background-color: white;
        top: -10px;
        bottom: -10px;
        left: -10px;
        right: -10px;
        box-shadow: 0px 0px 11px 0px #d7d7d7;
      }
    }
  }
  .product-name {
    font-size: 0.9em;
  }
  .fs13 {
    font-size: 13px;
  }
  .product-name-action {
    text-transform: uppercase;
  }
  @media (max-width: 767px) {
    .product-name {
      margin-top: 10px;
    }
  }
  .product-price {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    span {
      padding-right: 5px;
    }
    b {
      white-space: nowrap;
    }
  }
}
.product-link {
  &:hover {
    .product__link {
      z-index: 3;
    }
    .badge {
      z-index: 4;
    }
  }
}

.product-image-box {
  max-width: calc( 100% - 20px);
  @media (max-width: 767px){
    max-width: calc( 100% - 10px);
    margin-top: 5px;
  }
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60% auto;
  &__content {
    display: none;
  }
  &--loaded {
    background-image: none;

    .product-image__content {
      display: block;
    }
  }

}
.product-image-box {
  /deep/.product-image__thumb {
    height: 100%;
  }
}
.product-cover__thumb.video-open {
  opacity: 0;
}
.icon-video {
  .path4 {
    border: 1px solid black;
    border-radius: 50%;
    font-size: 30px;
  }
  background: none;
  border: 0;
  z-index: 1;
  position: absolute;
  right: 15px;
  top: 3px;
  padding: 0;
}
.vimeo-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vimeo-video {
  padding-top: 150%;
  /deep/ iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
.buy-button {
  max-width: 149px;
  margin: 5px 0 5px 10px;
  font-size: 12px;
  float: left;
  @media (min-width: 767px) {
    padding: 5px;
  }
  @media (max-width: 767px) {
    width: 90%;
    margin: 5px 0;
    float: unset;
  }
}
.product-sold-image {
  opacity: 0.61;
}
.product-sold-banner {
  cursor: default;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.55px;
  color: #FFFFFF;
  background-color: #000;
  padding: 5px 0;
  text-transform: uppercase;
  text-align: center;
}
.product-attribute {
  cursor: default;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px 10px;
  &.sold {
    background-color: #000;
  }
  &.new {
    background-color: #4BDEBB;
  }
  &.new-with-tag {
    background-color: #B595C9;
  }
  &.bestseller {
    background-color: #F3A1B5;
  }
}
.marketplace-designer {
  margin: 0 10px -5px 10px;
}
.line-through {
  text-decoration: line-through;
  font-weight: 300;
}
</style>
